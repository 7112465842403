import { isNud } from '@/utilities/typing';
import { dateYmd, dateYmdForInputDate } from '@/utilities/date-format';
import School from '@/models/entities/school';
import Event from '@/models/entities/event';
import Schedule from '@/models/entities/schedule';

/**
 * 予定のたまごトラン エンティティ
 */
class UndecidedSchedule {
    undecided_schedule_id;
    school;
    event;
    estimated_schedule_date;
    last_schedule_date;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.undecided_schedule_id = properties.undecided_schedule_id;
        this.school = new School(properties.school);
        this.event = new Event(properties.event);
        this.last_schedule = new Schedule(properties.last_schedule);
        this.estimated_schedule_date = properties.estimated_schedule_date;
    }

    get estimated_schedule_date_display() {
        return dateYmd(this.estimated_schedule_date);
    }
    get estimated_schedule_date_for_input() {
        return dateYmdForInputDate(this.estimated_schedule_date);
    }
}

export default UndecidedSchedule;
